export class LocationChat {
  /**
   * @param {string|null} id
   * @param {string|null} name
   * @param {string|null} description
   * @param {number|null} usersCount
   * @param {boolean} isOfficial
   * @param {number|null} reputation - minimal reputation of user to join chat
   */
  constructor({
    id = null,
    name = null,
    description = null,
    usersCount = null,
    isOfficial = false,
    reputation = null,
  } = {}) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.usersCount = usersCount;
    this.isOfficial = isOfficial;
    this.reputation = reputation;
  }
}

export class User {
  /**
   * @param {string|null} id
   * @param {string|null} name
   * @param {string|null} username
   * @param {number|null} reputation
   * @param {string|null} avatar
   */
  constructor({
    id = null,
    name = null,
    username = null,
    reputation = null,
    avatar = null,
  } = {}) {
    this.id = id;
    this.name = name;
    this.username = username;
    this.reputation = reputation;
    this.avatar = avatar;
  }
}

export class Message {
  /**
   * @param {string|null} id
   * @param {string|null} type in / out / system
   * @param {number|null} time
   * @param {number|null} timeUpdated
   * @param {string|null} username
   * @param {string|null} message
   * @param {boolean} pinned
   * @param {string|null} replyToId
   * @param {Message|null} replyTo
   * @param {Array<Message>|null} topicMessages
   * @param {string|null} avatar // Sender avatar. For now is sent over with every message
   * @param {number|null} reputation // Sender reputation
   */
  constructor({
    id = null,
    type = null,
    time = null,
    timeUpdated = null,
    username = null,
    message = null,
    pinned = false,
    replyToId = null,
    replyTo = null,

    // Sender information. TODO: remove from message
    avatar = null,
    reputation = null,

    topicMessages = [],
  } = {}) {
    this.id = id;
    this.type = type;
    this.time = time;
    this.timeUpdated = timeUpdated;
    this.username = username;
    this.message = message;
    this.pinned = pinned;
    this.replyToId = replyToId;
    this.replyTo = replyTo;
    this.topicMessages = topicMessages;

    // Sender info
    this.avatar = avatar;
    this.reputation = reputation;

    this.emojis = [];
    this.myEmojis = [];
  }

  toggleMyEmoji(emoji) {
    if (this.myEmojis.includes(emoji)) {
      this.myEmojis = this.myEmojis.filter(e => e !== emoji);
    } else {
      this.myEmojis.push(emoji);
    }
  }

  get emojisGrouped() {
    return this.emojis.reduce((a, c) => ({...a, [c]: (a[c] || 0) + 1}), {});
  }

  get messageFormatted() {
    return this.message?.replaceAll(/\n/g, '<br>').trim();
  }

  get isIn() {
    return 'i' === this.type;
  }

  get isOut() {
    return 'o' === this.type;
  }

  get isSystem() {
    return 's' === this.type;
  }

  get isAdmin() {
    return 'a' === this.type;
  }
}