import { createRouter, createWebHistory } from 'vue-router'
import WelcomeView from './views/WelcomeView.vue'
import RegisterView from './views/RegisterView.vue'
import ChatView from "@/views/ChatView.vue";
import ChatInfoView from "@/views/ChatInfoView.vue";

const routes = [{
    path: '/',
    name: 'home',
    component: WelcomeView,
  }, {
    path: '/register',
    name: 'register',
    component: RegisterView,
  }, {
    path: '/chat/:id',
    name: 'chat',
    component: ChatView,
    props: route => ({ chatId: route.params.id }),
  }, {
    path: '/chat/:id/info',
    name: 'chat-info',
    component: ChatInfoView,
    props: route => ({ chatId: route.params.id }),
}];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router
