<template>
  <section class="page bg-chat d-flex flex-column p-0 justify-content-between" v-if="chat">
    <div class="chat-header d-flex align-items-center justify-content-between mb-2" style="color: #39055e;">
      <button class="btn btn-link back-button" @click="$router.back()">
        <img class="wh22" src="../assets/back-triangle.svg" alt="Back"/>
      </button>

      <div class="d-flex flex-column align-items-center pt-2">
        <h4 class="header-username g-font m-0 font-size-28">{{ chat.name }}</h4>
        <p class="m-0 font-size-16 font-head-color" style="margin-top: -20px;">{{ chat.usersCount }}</p>
      </div>
      <button class="btn btn-link option-button" @click="$router.push({name: 'chat-info', params: {id: chatId}})">
        <img class="wh22" src="../assets/burger.svg" alt="Chat info"/>
      </button>
    </div>

    <div class="history-img d-flex flex-column justify-content-end align-items-center">
      <div class="history-title-block d-flex flex-column align-items-center">
        <h1 class="history-title g-font font-size-54 ls-3">INFO</h1>
        <h1 class="g-font font-size-24">INFO</h1>
      </div>
    </div>
    <article class="page-article px-4 py-2 d-flex flex-column align-items-center">
      <span class="px-4 py-2 text-center font-size-20">
        A company from Dublin, Ireland, that holds events across the world: Web Summit in Lisbon, Web Summit Rio in Rio de Janeiro, Web Summit Qatar in Doha, Collision in Toronto and RISE in Hong Kong.
        Web Summit brings together the people and companies redefining the global tech industry.
      </span>
    </article>
    <div class="info d-flex flex-column align-items-center">
      <a href="https://websummit.com/schedule">Schedule</a>
      <div class="social d-flex justify-content-between">
          <a href="https://www.facebook.com/WebSummitHQ"><img class="wh50" src="../assets/ui/facebook.svg" alt="facebook"></a>
          <a href="https://instagram.com/websummit"><img class="wh50" src="../assets/ui/instagram.svg" alt="instagram"></a>
          <a href="https://www.youtube.com/websummit"><img class="wh50" src="../assets/ui/youtube.svg" alt="youtube"></a>
      </div>
    </div>
  </section>
</template>

<script>
import {io} from "socket.io-client";
import {Message} from "@/app/model";

export default {
  name: "ChatInfoView",
  props: {
    chatId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chat: null,
    }
  },
  mounted() {
    console.log("Mounted");
    this.isLoading = true;
    this.$API.chats.get(this.chatId).then(chat => {
      this.chat = chat;
    }).finally(_ => this.isLoading = false);
  }
}
</script>

<style scoped>

</style>