<template>
  <div class="text-center" v-if="isLoading">
    <img src="../assets/loading.svg" title="loading" alt="loading"/>
  </div>

  <section class="page bg-chat d-flex flex-column p-0 justify-content-between" v-else-if="chat && messagesAll.length">
    <!-- Chat header -->
    <div class="chat-header d-flex align-items-center justify-content-between" style="color: #39055e;">
      <button class="btn btn-link back-button mb-2" @click="goBack">
        <img class="wh22" src="../assets/back-triangle.svg" alt="Back"/>
        <!--
        <svg class="icon icon-tabler icon-tabler-chevron-left" width="30" height="30" viewBox="0 0 24 24" stroke-width="2" stroke="#90a9fa" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M15 6l-6 6l6 6"></path>
        </svg>
        -->
      </button>

      <div class="d-flex flex-column align-items-center pt-2" @click="isExtendedChatInfo = !isExtendedChatInfo">
        <h4 class="header-username g-font m-0 font-size-28">
          <template v-if="messageTopic && messageTopic.id === -1">
            Chat with owner
          </template>
          <template v-else>
            {{ chat.name }}
          </template>
        </h4>
        <p class="m-0 font-size-16 font-head-color">{{ chat.usersCount }}</p>
      </div>
      <button class="btn btn-link option-button" @click="$router.push({name: 'chat-info', params: {id: chatId}})">
        <img class="wh22" src="../assets/burger.svg" alt="Chat info"/>

        <!--
        <svg class="icon icon-tabler icon-tabler-align-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#90a9fa" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M4 6l16 0"></path>
          <path d="M4 12l10 0"></path>
          <path d="M4 18l14 0"></path>
        </svg>
        -->
      </button>
    </div>

    <!-- Chat pinned messages -->
    <div class="notification-header d-flex justify-content-between px-5 py-2 align-items-end" v-if="isExtendedChatInfo">
      <p class="m-0 fs-6 w-100 text-center" style="color: grey; cursor: pointer;">
        <!--
        <a href="#" style="color: grey; text-decoration: none;" @click.prevent="writeToChatOwner">Write to chat owner</a> |
        -->
          <a href="#" style="color: grey; text-decoration: none;" @click.prevent="inviteChatOwner">Invite chat owner</a>
      </p>
    </div>

    <div class="notification-header d-flex justify-content-between px-5 py-2 align-items-end" v-else-if="messagesPinnedCurrent">
        <div class="d-flex">
          <img class="wh20" src="../assets/chat-info.svg" alt="Chat info"/>

              <!--
            <svg class="icon icon-tabler icon-tabler-alert-circle" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#A9A9A9" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
              <path d="M12 8v4"></path>
              <path d="M12 16h.01"></path>
            </svg>
            -->
          <p class="my-0 mx-1 fs-6" style="color: grey; cursor: pointer;" @click="scrollChatTo(messagesPinnedCurrent)" v-html="messagesPinnedCurrent.messageFormatted"></p>

      </div>
      <span style="color: grey;">
        <template v-if="messagesPinned.length > 1">
          <a href="#" @click.prevent="pinnedMessagePositionInc(-1)" style="text-decoration: none!important; color: grey;">&lt;</a>
          {{ messagePinnedPosition + 1 }} / {{ messagesPinned.length }}
          <a href="#" @click.prevent="pinnedMessagePositionInc(1)" style="text-decoration: none!important; color: grey;">&gt;</a>
        </template>
      </span>
    </div>

    <div class="alert alert-info" v-if="messageTopic">
      <ChatMessageBubbleComponent :message="messageTopic" @emoji-toggle="onEmojiToggle" @reply="onMessageReplySelected" @pin="onMessagePin" no-topic-footer/>
    </div>



    <div class="chat-message-block d-flex p-3 flex-column" style="flex: 1;" ref="messages">
      <div v-for="message in messages" :ref="`message-${message.id}`">

        <div class="message d-flex m-2 justify-content-start" style="width: 100%" v-if="message.isIn">
          <div class="avatar">
            <div class="rounded-circle wh50 my-1 justify-content-center align-items-center d-flex" :style="{ backgroundColor: this.getAvatarColor(message.username) }">
              <span :style="{ color: this.getOppositeColor(message.username) }">{{ this.getAvatarSymbol(message.username) }}</span>
            </div>
            <div class="star-box">
              <svg class="icon icon-tabler icon-tabler-star-filled" width="10" height="10" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                   stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                    d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z"
                    stroke-width="0" fill="#F18DAB"></path>
              </svg>
            </div>
          </div>

          <ChatMessageBubbleComponent :message="message" @emoji-toggle="onEmojiToggle" @reply="onMessageReplySelected" @pin="onMessagePin" @topic="onMessageTopicSelected"/>

          <!--
          <div class="message-content-block rounded-pill m-2">
            <div class="message-header d-flex">
              <span class="user-name sub-text">{{ message.username }}</span>
              <span class="sub-text">/</span>
              <span class="message-time sub-text">11 may, 16:22</span>
            </div>


            <div class="bg-white p-2 pb-4" style="max-width__: 350px; width: 350px; border-radius: 20px; padding: 15px!important;">
              <p class="message-text m-0" v-html="message.messageFormatted"></p>

              <div class="d-flex p-1">
                <div class="d-flex align-items-center">
                  <i class="bi bi-emoji-smile mx-1" style="color: rgb(138, 39, 134)"></i>
                  <span style="color: rgb(138, 39, 134)">23</span>
                </div>
                <div class="d-flex align-items-center">
                  <i class="bi bi-emoji-angry mx-1" style="color: rgb(138, 39, 134)"></i>
                  <span style="color: rgb(138, 39, 134)">112</span>
                </div>
              </div>
            </div>

            <div class="reactions d-flex justify-content-end">
              <div class="message-emoji-item p-3 rounded-circle m-1 d-flex justify-content-center align-items-center justify-content-end">
                <i class="bi bi-emoji-smile"></i>
              </div>
              <div class="message-emoji-item p-3 rounded-circle m-1 d-flex justify-content-center align-items-center justify-content-end">
                <i class="bi bi-emoji-angry"></i>
              </div>
              <div class="message-emoji-item p-3 rounded-circle m-1 d-flex justify-content-center align-items-center justify-content-end">
                <i class="bi bi-reply"></i>
              </div>
            </div>
          </div>
          -->
        </div>



        <div class="message d-flex m-2 justify-content-end" v-if="message.isOut" style="padding-left: 80px;">
          <ChatMessageBubbleComponent :message="message" @emoji-toggle="onEmojiToggle" @reply="onMessageReplySelected" @pin="onMessagePin" @topic="onMessageTopicSelected"/>
        </div>


        <div class="alert alert-warning mt-2" v-if="message.isAdmin">
          <div>
            <strong>Message from admin</strong>
          </div>

          <div v-html="message.messageFormatted"></div>
        </div>
      </div>


      <!--
      <div class="message d-flex m-2 justify-content-start">
        <div class="avatar">
          <div class="rounded-circle border border-secondary-subtle" style="width: 50px; height: 50px">
            < !-- <img src="" alt="avatar"> -- >
          </div>
          <div>
            <svg class="icon icon-tabler icon-tabler-star-filled" width="10" height="10" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                 stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                  d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z"
                  stroke-width="0" fill="orange"></path>
            </svg>
          </div>
        </div>

        <div class="rounded-pill m-2">
          <div class="message-header d-flex">
            <span class="user-name sub-text">John Doe</span>
            <span class="sub-text">/</span>
            <span class="message-time sub-text">11 may, 16:22</span>
          </div>

          <div class="bg-white p-2" style="width: 350px; border-radius: 20px">
            <p class="message-text m-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus iste
              maiores, amet incidunt officiis dolor ab inventore rerum nemo sequi non id architecto
              commodi! Mollitia labore possimus reprehenderit asperiores dignissimos?</p>


          </div>
        </div>
      </div>

      <div class="message d-flex m-2 justify-content-end">
        <div class="avatar">
          <div class="rounded-pill m-2">
            <div class="message-header d-flex justify-content-end">
              <span class="user-name sub-text">John Doe</span>
              <span class="sub-text">/</span>
              <span class="message-time sub-text">11 may, 16:22</span>
            </div>
            <div class="bg-white p-2" style="width: 350px; border-radius: 20px">
              <p class="message-text m-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus
                iste maiores</p>
            </div>
          </div>
        </div>
      </div>
      -->

    </div>

    <div class="alert alert-info mb-0" v-if="messageReply">
      <a href="#" @click.prevent="messageReply = null" class="float-end">&times;</a>
      Replying to: {{ messageReply.messageFormatted }}
    </div>


    <div class="chat-input">
        <div class="border border-white d-flex mx-3 mb-3 justify-content-center page-element-radius white-bg">
            <input class="border border-white page-element-radius" style="flex: 1" type="text" ref="messageText" v-model="messageText" @keydown.enter="doSend" placeholder="Type a message...">
            <button class="border border-white rounded-circle blue-bg px-1" @click="doSend" style="border: none!important; background-color: transparent!important;">
                <img class="wh30" src="../assets/send-message.svg" alt="Send message" />
            </button>
        </div>
    </div>

    <template v-if="'admin' === username">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="admin-message" v-model="messageAsAdmin">
        <label class="form-check-label" for="admin-message">
          as admin
        </label>
      </div>
    </template>
  </section>

  <template v-if="false">

  <div class="container" v-if="chat && messagesAll.length">
    <div class="first-div" style="border-bottom: 1px solid grey; background-color: #EEE; text-align: center; padding: 20px;" @click="isExtendedChatInfo = !isExtendedChatInfo">
      <h1>{{ chat.name }}</h1>
      Active users: 123

      <template v-if="isExtendedChatInfo">
        <div class="row">
          <!--
          <div class="col">
            <a href="#" class="btn btn-primary w-100" @click.prevent="writeToChatOwner">Write to chat owner</a>
          </div>
          -->
          <div class="col">
            <a href="#" class="btn btn-primary w-100" @click.prevent="inviteChatOwner">Invite chat owner</a>
          </div>
        </div>
      </template>
    </div>

    <div class="first-div" v-if="messageTopic">
      <div class="bubble">
        <i class="fa-solid fa-arrow-left-long" @click.prevent="messageTopic = null" style="cursor: pointer;"></i>
        <ChatMessageBubbleComponent :message="messageTopic" @emoji-toggle="onEmojiToggle" @reply="onMessageReplySelected" @pin="onMessagePin" no-topic-footer/>
      </div>
    </div>

    <div class="first-div" v-else-if="messagesPinned.length">
      <div class="alert alert-info mb-1" style="cursor: pointer;" v-for="message in messagesPinned" @click="scrollChatTo(message)">
        <strong>Pinned:</strong> {{ message.messageFormatted }}
      </div>
    </div>

    <div class="second-div" ref="messages">
      <div class="messages">
        <div v-for="message in messages" :key="`message-${message.id}`" :ref="`message-${message.id}`">
          <div class="row mt-2" v-if="message.isIn">
            <div class="col-2 text-center pt-5">
              <img :src="message.avatar" alt="avatar" class="avatar" width="43" v-if="message.avatar"/>
              <img src="../assets/avatar.png" alt="avatar" class="avatar" width="43" v-else/>
            </div>
            <div class="col-10">
              <div class="bubble left">
                <ChatMessageBubbleComponent :message="message" @emoji-toggle="onEmojiToggle" @reply="onMessageReplySelected" @pin="onMessagePin" @topic="onMessageTopicSelected"
                                            :no-topic-footer="messageTopic"/>
              </div>
            </div>
          </div>

          <div class="row mt-2" v-if="message.isOut">
            <div class="col-8 offset-4">
              <div class="bubble right">
                <ChatMessageBubbleComponent :message="message" @emoji-toggle="onEmojiToggle" @reply="onMessageReplySelected" @pin="onMessagePin" @topic="onMessageTopicSelected"
                                            :no-topic-footer="messageTopic"/>
              </div>
            </div>
          </div>

          <div class="alert alert-warning mt-2" v-if="message.isAdmin">
            <div>
              <strong>Message from admin</strong>
            </div>

            <div v-html="message.messageFormatted"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="third-div">
      <div class="alert alert-info" v-if="messageReply">
        <a href="#" @click.prevent="messageReply = null" class="float-end">&times;</a>

        Replying to: {{ messageReply.messageFormatted }}
      </div>
      <div class="row">
        <div class="col-10">
          <textarea class="form-control" v-model="messageText" ref="messageText" placeholder="Type message" @keydown.enter="doSend"></textarea>

          <template v-if="'admin' === username">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="admin-message" v-model="messageAsAdmin">
              <label class="form-check-label" for="admin-message">
                as admin
              </label>
            </div>
          </template>
        </div>
        <div class="col-2">
          <button @click="doSend" class="w-100 h-100">
            <i class="fa-solid fa-send"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  </template>
</template>

<script>
import ColorHash from 'color-hash'
import {io} from "socket.io-client";
import {mapGetters} from "vuex";
import moment from "moment";
import {Message} from "@/app/model";
import ChatMessageBubbleComponent from "@/views/ChatMessageBubbleComponent.vue";

let socket = null;

const writeToChatOwnerMessage = new Message({
  id: -1,
  message: 'Chat with owner',
});

export default {
  name: "ChatView",
  components: {ChatMessageBubbleComponent},
  props: {
    chatId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      connectionStatus: 0,
      messagesLog: [],
      messagesAll: [],
      chat: null,
      isLoading: null,

      isExtendedChatInfo: false,

      messageText: '',
      messageAsAdmin: false,
      messageReply: null,
      messageTopic: null,

      messagePinnedPosition: 0,
    };
  },
  computed: {
    ...mapGetters(['username', 'avatar']),
    messages() {
      if (this.messageTopic) {
        return this.messageTopic.topicMessages;
      }

      return this.messagesLog;
    },
    messagesPinned() {
      return this.messagesAll.filter(m => m.pinned);
    },
    moment: _ => moment,
    messagesPinnedCurrent() {
      if (!this.messagesPinned) {
        return null;
      }

      return this.messagesPinned[this.messagePinnedPosition];
    },
  },
  methods: {
    getAvatarColor(username) {
        const colorHasher = new ColorHash();
        return colorHasher.hex(username);
    },
    getAvatarSymbol(username) {
      return username[0].toUpperCase();
    },
    getOppositeColor(username) {
      const color = this.getAvatarColor(username)
      const red = parseInt(color.substring(1, 3), 16);
      const green = parseInt(color.substring(3, 5), 16);
      const blue = parseInt(color.substring(5, 7), 16);

      const oppositeRed = 255 - red;
      const oppositeGreen = 255 - green;
      const oppositeBlue = 255 - blue;

      return `#${oppositeRed.toString(16)}${oppositeGreen.toString(16)}${oppositeBlue.toString(16)}`;
    },
    pinnedMessagePositionInc(delta) {
      let position = this.messagePinnedPosition + delta;
      position = Math.max(0, position);
      position = Math.min(position, this.messagesPinned.length - 1);

      this.messagePinnedPosition = position;
    },
    doSend(event) {
      const messageText = this.messageText.trim();
      if (!messageText) {
        return;
      }

      if (event && 'Enter' === event.key && (event.ctrlKey || event.shiftKey)) {
        return;
      }

      socket.emit("message", {
        type: this.messageAsAdmin ? 'a' : null,
        message: this.messageText?.trim(),
        replyToId: this.messageReply?.id,
        topicId: this.messageTopic?.id,
        avatar: this.avatar,
        pinned: this.pinned,
      });

      this.messageText = '';
      this.messageAsAdmin = false;
      this.messageReply = null;

      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    onEmojiToggle({message, emoji, action}) {
      socket.emit("emoji", {
        messageId: message.id,
        emoji: emoji,
        action,
      });
    },
    onMessageReplySelected({message}) {
      this.messageReply = message;

      this.$refs.messageText.focus();
    },
    onMessagePin({message}) {
      console.log(arguments);
      message.pinned = !message.pinned;
      socket.emit("message", {
        id: message.id,
        type: message.type,
        message: message.message,
        replyToId: message.replyToId,
        topicId: message.topicId,
        avatar: message.avatar,
        pinned: message.pinned,
      });
    },
    onMessageTopicSelected({message}) {
      this.messageTopic = message;
    },
    writeToChatOwner() {
      this.messageTopic = writeToChatOwnerMessage;
      this.isExtendedChatInfo = false;
    },
    inviteChatOwner() {
      alert('Invitation was sent. Thank you!');
    },
    scrollChatTo(message) {
      console.log(message.id);
      this.$nextTick(_ => {
        const el = this.$refs[`message-${message.id}`];

        if (el) {
          this.$refs.messages.scroll({top: el[0].scrollHeight - 100, behavior: 'smooth'});
        }
      });
    },
    goBack() {
      if (this.messageTopic) {
        this.messageTopic = null;

        return;
      }

      this.$router.back();
    },
  },
  mounted() {
    // TODO: could be bad idea
    this.messagesAll.push(writeToChatOwnerMessage);

    console.log("Mounted");
    this.isLoading = true;
    this.$API.chats.get(this.chatId).then(chat => {
      this.chat = chat;
    }).finally(_ => this.isLoading = false);

    this.connectionStatus = 1;
    // const url = "http://192.168.1.73:3123";
    const url = "https://io-locals.ryabenko.pro";

    socket = io(url, {
      reconnectionDelayMax: 10000,
    });

    socket.on("connect", _ => {
      socket.emit('join', {
        username: this.username,
        room: this.chatId,
      });
    });

    let onMessage = ({id, message, type, username, replyToId, topicId, avatar, pinned}) => {
      console.log("Message received");

      const existing = this.messagesAll.find(m => m.id === id);

      if (existing) {
        existing.id = id;
        existing.timeUpdated = (new Date());
        existing.message = message;
        existing.pinned = pinned;
        existing.replyToId = replyToId;
        existing.replyTo = this.messagesAll.find(m => m.id === replyToId);
      } else {
        const entity = new Message({
          id,
          type: username === this.username ? 'o' : type,
          time: (new Date()),
          message,
          pinned,
          username,
          replyToId,
          replyTo: this.messagesAll.find(m => m.id === replyToId),
          topicId,
          avatar,
        });

        this.messagesAll.push(entity);
        if (topicId) {
          this.messagesAll.find(m => m.id === topicId)?.topicMessages?.push(entity);
        } else {
          this.messagesLog.push(entity);
        }

        this.$nextTick(_ => {
          this.$refs.messages.scroll({top: this.$refs.messages.scrollHeight - 100, behavior: 'smooth'});
        });
      }
    };

    socket.on("messages", ({messages}) => {
      messages?.forEach(message => onMessage(message));
    });
    socket.on("message", onMessage);

    socket.on("emoji", ({messageId, emoji, action}) => {
      const message = this.messagesAll.find(m => m.id === messageId);
      if (!message) {
        return;
      }

      if ("add" === action) {
        message.emojis.push(emoji);
      } else {
        const index = message.emojis.findIndex(e => e === emoji);
        message.emojis.splice(index, 1);
      }
    });

    document.addEventListener('keyup', event => {
      switch (event.key) {
        case 'Escape':
          if (this.messageReply) {
            this.messageReply = null;
          }
      }
    });
  },
  unmounted() {
    socket.disconnect();
  }
}
</script>

<style scoped>

</style>
