import {LocationChat} from "@/app/model";

const chats = {
  '1': new LocationChat({id: '1', name: 'Web Summit Lisbon', usersCount: 1304, reputation: 0, isOfficial: true}),
  // '2': new LocationChat({id: '2', name: 'Estadlo do sport', description: 'Lisboa e Benfica', usersCount: 45, reputation: 1}),
  // '3': new LocationChat({id: '3', name: 'Cascais Marina event chat', description: 'May 31, 2023', usersCount: 12, reputation: 100}),
};
export default function (domain) {
  return {
    chats: {
      list() {
        return new Promise(resolve => {
          setTimeout(_ => resolve(chats), Math.random() * 2000);
        })
      },
      get(id) {
        return new Promise(resolve => {
          setTimeout(_ => resolve(chats[id]), Math.random() * 10);
        });
      },
    },
  };
};