import {createStore} from "vuex";
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default createStore({
  state () {
    return {
      name: null,
      username: null,
      avatar: null,
    }
  },
  getters: {
      name: state => state.name,
      username: state => state.username,
      avatar: state => state.avatar,
  },
  mutations: {
    name(state, name) {
      state.name = name;
    },
    username(state, username) {
      state.username = username;
    },
    avatar(state, avatar) {
      state.avatar = avatar;
    },
  },
  actions: {
    register({commit}, {name, username, avatar}) {
      console.log("register", name, username);
      commit('name', name);
      commit('username', username);
      commit('avatar', avatar);
    },
  },
  plugins: [vuexLocal.plugin],
});
