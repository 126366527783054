<template>
  <div class="message-content-block rounded-pill m-2" @dblclick="$emit('reply', {message})">
    <div class="message-header d-flex mb-1">
      <span class="user-name sub-text font-size-12" style="font-weight: bolder;">@{{ message.username }}</span>
      <span class="sub-text mx-1">/</span>
      <span class="message-time sub-text">{{ messageTime }}</span>
    </div>


    <div class="bg-white p-2 pb-4" style="max-width__: 350px; min-width: 280px; border-radius: 20px;">
      <div class="small text-muted" v-if="message.replyTo">
        Reply to: <span v-html="message.replyTo.messageFormatted"></span>
      </div>

      <p class="message-text m-0" v-html="message.messageFormatted"></p>

      <div class="d-flex p-1 font-size-16">
        <template v-for="(count, emoji) in message.emojisGrouped">
          <div class="d-flex align-items-center" @click="toggleEmoji(message, emoji)">
            <i class="bi  mx-1" :class="[`bi-emoji-${emoji}`]" style="color: rgb(138, 39, 134)"></i>
            <span style="color: rgb(138, 39, 134); margin-right: 10px;">{{ count }}</span>
          </div>
        </template>
      </div>
    </div>

    <div class="reactions d-flex justify-content-end">
      <a href="#" style="color: #8a2786;" class="message-emoji-item p-3 rounded-circle m-1 d-flex justify-content-center align-items-center justify-content-end" @click.prevent="toggleEmoji(message, 'smile')">
        <img class="wh25 bi bi-emoji-smile" src="../assets/ui/smile.svg" alt="smile">
      </a>
      <a href="#" style="color: #8a2786;" class="message-emoji-item p-3 rounded-circle m-1 d-flex justify-content-center align-items-center justify-content-end" @click.prevent="toggleEmoji(message, 'frown')">
        <img class="wh25 bi bi-emoji-frown" src="../assets/ui/cry.svg" alt="cry">
      </a>
      <a href="#" style="color: #8a2786;" class="message-emoji-item p-3 rounded-circle m-1 d-flex justify-content-center align-items-center justify-content-end" @click.prevent="$emit('reply', {message})">
        <img class="wh25 bi bi-reply" src="../assets/ui/reply.svg" alt="reply">
      </a>
      <a href="#" style="color: #8a2786;" class="message-emoji-item p-3 rounded-circle m-1 d-flex justify-content-center align-items-center justify-content-end" @click.prevent="$emit('topic', {message})" v-if="false && !noTopicFooter">
        <i class="bi bi-three-dots"></i>
      </a>
      <a href="#" style="color: #8a2786;" class="message-emoji-item p-3 rounded-circle m-1 d-flex justify-content-center align-items-center justify-content-end" @click.prevent="$emit('pin', {message})" v-if="'admin' === username">
        <i class="bi bi-pin"></i>
      </a>
    </div>
  </div>


  <template v-if="false">
  <div class="bubble-content" @dblclick="$emit('reply', {message})">
    <div class="reply-to text-muted" style="border-bottom: 1px solid grey;" v-if="message.replyToId">
      Replying to:
      <template v-if="message.replyTo">
        {{ message.replyTo.messageFormatted }}
      </template>
      <template v-else>
        <i>Message unavailable</i>
      </template>
    </div>

    <i class="fa-solid fa-thumb-tack float-end ms-2" @click="$emit('pin', {message})" v-if="'admin' === username"></i>
    <i class="fa-solid fa-reply float-end ms-2" @click="$emit('reply', {message})"></i>

    <span class="float-end">
      {{ messageTime }}
    </span>
    <div><strong>{{ message.username }}</strong></div>

    <div v-html="message.messageFormatted"></div>

    <div style="min-height: 30px;">
      <div class="dropdown dropstart float-end">
        <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fa-solid fa-icons"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-dark">
          <a class="me-1" href="#" v-for="emoji in smiles" @click.prevent="toggleEmoji(message, emoji)">{{ emoji }}</a>
        </div>
      </div>

      <span class="me-1 badge" :class="{'text-bg-primary': message.myEmojis.includes(emoji)}" v-for="(count, emoji) in message.emojisGrouped" @click="toggleEmoji(message, emoji)">
        {{ emoji }} {{ count }}
      </span>
    </div>

    <div class="topic-footer" v-if="!noTopicFooter">
      <a href="#" @click.prevent="$emit('topic', {message})">Comments: {{ message.topicMessages.length }}</a>
    </div>

  </div>
  </template>

</template>
<script>

import {Message} from "@/app/model";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: 'ChatMessageBubbleComponent',
  emits: ['emoji-toggle', 'reply', 'topic', 'pin'],
  props: {
    message: {
      type: Message,
      required: true,
    },
    noTopicFooter: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(['username']),
    moment() {
      return moment;
    },
    messageTime() {
      return moment(this.message.time).format('DD MMM, HH:mm');
    },
    smiles: _ => ['🙃', '🤑', '🤗', '🤔', '🤐', '🙄', '🤒', '🤕', '🤓', '🤖', '🤘🏻'],
  },
  methods: {
    toggleEmoji(message, emoji) {
      const alreadySet = message.myEmojis.includes(emoji);
      message.toggleMyEmoji(emoji);

      this.$emit('emoji-toggle', {message, emoji, action: alreadySet ? 'remove' : 'add'});
    },
  },
}
</script>

<style scoped>
.reactions {
  display: none!important;
  margin-bottom: -23px;
}

.message-content-block:hover .reactions {
  display: flex!important;
  transition: 0.2s;
}
</style>
