<template>

  <section class="page bg-page d-flex flex-column align-items-center justify-content-between">
    <div class="first-page-title d-flex flex-column align-items-center">
      <h3 class="font-color-subtitle font-size-20 g-font fw200">Welcome to</h3>
      <div class="d-flex align-items-center">
                <div class="rounded-circle wh50">
                    <img src="../assets/ui/logo_clear.svg" alt="logo">
                </div>
                <h1 class="mx-3 my-0 g-font font-size-68 font-color-white">Dentro</h1>
            </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-between">
      <form class="input-reg-block" @submit.prevent="doRegister">
      <div class="rounded-pill py-1 px-3 d-flex width300 input-bg my-4">
        <input class="input-reg rounded-pill p-1 input-bg g-font" style="color: #fff;" v-model="username"  placeholder="PEASE ENTER YOUR NICKNAME">
        <button class="input-bg border border-0" type="submit" v-if="username">
          <img class="reg-btn" src="../assets/register-arrow.svg" alt="Register"/>
          <!--
          <svg  class="icon icon-tabler icon-tabler-chevron-right" width="40" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="#f58dab" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M9 6l6 6l-6 6"></path>
          </svg>
          -->
        </button>
      </div>
    </form>
    <p class="mvp-text font-color-version font-size-13">MVP version 1.0</p>
    </div>
  </section>

  <template v-if="false">
  <div class="container py-5">
    <div class="align-content-center my-5">
      <img src="../assets/logo.svg" title="logo" alt="logo"/>
      <h1 style="color: #3B82F6; margin-top: 10px; font-size: 28px;">Local</h1>
    </div>
    <h1>Registration</h1>

    <form @submit.prevent="doRegister">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label for="username" class="form-label">Username</label>
            <input type="text" class="form-control" id="username" v-model="username">
          </div>
        </div>
      </div>

      <!--
      <div class="row">
        <div class="col-12">
          <a class="btn btn-info w-100" @click="show = !show">Set Avatar</a>

          <img width="64" class="avatar" :src="imgDataUrl" alt="Avatar" v-if="imgDataUrl">

          <ImageUpload
              field="img"
              @crop-success="cropSuccess"
              v-model="show"
              :width="250"
              :height="250"
              lang-type="en"
              img-format="png"></ImageUpload>
        </div>
      </div>
      -->

      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <button class="btn btn-primary w-100 mt-5">Register</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  </template>
</template>

<script>
import {mapActions} from "vuex";
import ImageUpload from 'vue-image-crop-upload';

export default {
  name: "RegisterView",
  components: {ImageUpload},
  data() {
    return {
      name: null,
      username: null,
      imgDataUrl: null,
      show: false,
    };
  },
  methods: {
    ...mapActions(['register']),
    doRegister() {
      this.register({name: this.name, username: this.username, avatar: this.imgDataUrl});
      this.$router.replace({name: 'home'});
    },
    cropSuccess(imgDataUrl, field) {
      this.imgDataUrl = imgDataUrl;
    },
  }
}
</script>

<style scoped>
/*
.avatar {
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 1px;
}
 */
</style>
