import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import API from './app/api/index'


import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap.css'

import 'font-awesome/css/font-awesome.css'

import './assets/style.css'

const app = createApp(App);
app.use(router);
app.use(store);
app.config.globalProperties.$API = API();

app.mount('#app');


/*
Test App ID
tm7siBA3
Secret key (use this for calling the REST API)
sk_test_K9cbl3AjAnp4Mkiichifz9vvwmsIwomJ
Test app name
Ryabenko Sergey
 */