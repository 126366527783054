<template>
  <section class="page bg-page d-flex flex-column align-items-center justify-content-center">
    <div class="page-block d-flex flex-column align-items-center justify-content-between">
      <h1 class="text-center big-title g-font font-size-68">Where<br>are you <br> now?</h1>

      <template v-if="!isGeoSuccess && !isGeoError">
        <h1>Please allow the page to get your location so we can select appropriated chats</h1>
      </template>

      <template v-if="isGeoError">
        <h1>Error getting your location. Please enable geolocation to see chats available in your region.</h1>
      </template>


      <template v-if="isGeoSuccess">
        <div class="d-flex flex-column align-items-center width350 mb-4" style=" cursor: pointer; margin-top: 50px" @click="join({id: 1})">
          <div class="page-element-radius input-bg p-2 py-3 d-flex flex-column justify-content-center" style=" width: 100%; ">
            <h1 class="text-center font-color-white m-0 g-font fw200 font-size-28 ls-3">Web Summit Lisbon</h1>
            <p class="count text-center font-color-white m-1">1 304</p>
          </div>
          <div class="logo-welcome-page rounded-circle wh36">
            <img class="wh36" src="../assets/logo-small.svg" alt="Logo">
          </div>
        </div>

        <!--
      <div class="last-block-welcome d-flex flex-column align-items-center" style=" cursor: pointer; " @click="join({id: 2})">
          <div class="page-element-radius input-bg py-3 px-2 d-flex input-bg flex-column justify-content-center width300">
            <h2 class="text-center g-font font-color-subtitle font-size-20 m-0 fw200 ls-3">Estadlo do sport</h2>
            <h2 class="text-center g-font font-color-subtitle font-size-20 m-0 fw200 ls-3">Lisboa e Benfica</h2>
            <p class="count text-center font-color-subtitle font-size-14 m-0"> 1430</p>
          </div>
          <div class="sub-text-welcome rounded-circle">
            <h2 class="text-center g-font font-main-color font-size-16" >&ap;230m</h2>
          </div>
        </div>
      -->
      </template>
    </div>
  </section>


  <template v-if="false">
  <div class="container py-5">
    <div class="text-center mb-5">
      <img src="../assets/logo.svg" title="logo" alt="logo"/>
      <h1 style="color: #3B82F6; margin-top: 10px; font-size: 28px;">Local</h1>
    </div>

    <template v-if="isGeoSuccess">
      <div class="text-center" v-if="isLoading">
        <img src="../assets/loading.svg" title="loading" alt="loading"/>
      </div>

      <template v-else>
        Please select chat you want to join

        <div class="card mb-3" v-for="chat in chats" style="cursor: pointer;" @click="join(chat)">
          <div class="card-header">
            <div class="float-end">
              <i class="fa-solid fa-certificate" v-if="chat.isOfficial" title="Official chat"></i>
            </div>

            <i class="fa-solid fa-user"></i> {{ chat.usersCount }}
          </div>
          <div class="card-body">
            <h5 class="card-title">{{ chat.name }}</h5>
            <p class="card-text">{{ chat.description }}</p>
          </div>
        </div>
      </template>

    </template>
  </div>
  </template>


</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "WelcomeView",
  data() {
    return {
      chats: null,
      isLoading: false,
      isGeoSuccess: true,
      isGeoError: false,
    };
  },
  computed: {
    ...mapGetters(['name', 'username']),
  },
  mounted() {
    if (!this.username) {
      return this.$router.replace({name: 'register'});
    }

    navigator.geolocation.getCurrentPosition(_ => {
      this.isGeoSuccess = true;

      this.isLoading = true;
      this.$API.chats.list().then(chats => {
        this.chats = chats;
      }).finally(_ => this.isLoading = false);
    }, _ => {
      // this.isGeoError = true;
      this.isGeoSuccess = true;

      this.isLoading = true;
      this.$API.chats.list().then(chats => {
        this.chats = chats;
      }).finally(_ => this.isLoading = false);
    });
  },
  methods: {
    join(chat) {
      this.$router.push({name: 'chat', params: {id: chat.id}});
    },
  }
}
</script>

<style scoped>

</style>
